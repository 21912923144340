import React from 'react';
import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';
import Img from '../../../utils/OptimizedImage';

const CaseStudyDetailHero = ({ caseStudy }) => (
  <HeroContainer>
    <div className="content">
      <div className="heading-kicker">Case Study</div>
      <h1 className="heading">{caseStudy?.title}</h1>
      <p className="summary">{caseStudy?.summary}</p>
      <ul className="key-results">
        {
          caseStudy?.keyResults?.links?.length > 0 &&
          caseStudy.keyResults.links.map(listItem => {
            return (
              <li key={listItem.subhead}>
                {
                  listItem?.iconImage?.gatsbyImageData ?
                    (
                      <Img
                        className="icon"
                        image={listItem?.iconImage?.gatsbyImageData}
                        alt={listItem?.iconImage?.alt}
                      />
                    )
                    :
                    (
                      <Img
                        className="icon"
                        src={listItem?.iconImage?.url}
                        alt={listItem?.iconImage?.alt}
                      />
                    )
                }
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: listItem?.subhead }}
                />
              </li>
            );
          })
        }
      </ul>
      <figure className="featured-image">
        {
          caseStudy?.thumbnail?.gatsbyImageData ?
            (
              <Img
                image={caseStudy?.thumbnail?.gatsbyImageData}
                alt={caseStudy?.thumbnail?.alt}
                width={caseStudy?.thumbnail?.width}
                height={caseStudy?.thumbnail?.height}
              />
            )
            :
            (
              <Img
                src={caseStudy?.thumbnail?.url}
                alt={caseStudy?.thumbnail?.alt}
                width={caseStudy?.thumbnail?.width}
                height={caseStudy?.thumbnail?.height}
              />
            )
        }
      </figure>
    </div>
  </HeroContainer>
);

export default CaseStudyDetailHero;

const HeroContainer = styled.div`
  padding: 60px 0 56px;
  text-align: left;

  ${atMinWidth.md`
    padding: 150px 0 96px;
  `}

  ${atMinWidth.lg`
    text-align: center;
  `}

  ${atMinWidth.xl`
    padding-bottom: 80px;
  `}

  .heading-kicker {
    ${font('overline', 'sm', '700')}
    color: ${colors.primary[700]};
    max-width: 770px;
    margin: 0 auto;
  }

  .heading {
    ${font('display', 'md', '700')}
    color: ${colors.gray[900]};
    max-width: 770px;
    margin: 24px auto 0;

    ${atMinWidth.md`
      ${font('display', 'lg', '700')}
      margin-top: 32px;
    `}

    ${atMinWidth.lg`
      ${font('display', 'xxl', '700')}
    `}
  }

  .summary {
    ${font('text', 'md', '400')}
    color: ${colors.gray[600]};
    max-width: 770px;
    margin: 32px auto 0;

    ${atMinWidth.sm`
      ${font('text', 'xl', '400')}
    `}
  }

  .key-results {
    list-style: none;
    max-width: 970px;
    margin: 32px auto 0;
    padding: 0 12px;
    text-align: left;

    ${atMinWidth.sm`
      text-align: center;
      margin-top: 40px;
      padding: 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 30px;
    `}

    ${atMinWidth.md`
      gap: 80px;
    `}

    > li + li {
      margin-top: 32px;

      ${atMinWidth.sm`
        margin-top: 0;
      `}
    }

    .icon {
      display: block;
      width: 40px;
      height: 40px;

      ${atMinWidth.sm`
        margin: 0 auto;
      `}
    }

    .text {
      ${font('text', 'md', '700')}
      margin-top: 16px;

      ${atMinWidth.lg`
        ${font('text', 'xl', '700')}
      `}

      div {
        display: contents;
      }
    }
  }

  .featured-image {
    display: block;
    position: relative;
    background: #fff;
    max-width: 1170px;
    height: 220px;
    margin: 40px auto 0;
    border-radius: 16px;
    overflow: hidden;
    ${shadow('lg')}

    ${atMinWidth.xs`
      height: 300px;
    `}

    ${atMinWidth.sm`
      height: 360px;
    `}

    ${atMinWidth.md`
      margin-top: 64px;
      height: 470px;
      ${shadow('xxl')}
    `}

    ${atMinWidth.lg`
      height: 630px;
    `}

    .gatsby-image-wrapper {
      position: static;
    }

    img {
      position: absolute;
      inset: 0;
      object-fit: cover;
    }
  }
`;
